<template>
    <div>
        <myHead/>
        <div>
            <a-result>
                <template #title>
                    <span style="letter-spacing:2px">哎呀，您还没有登录~</span>
                </template>
                <template #subTitle>
                    <span style="letter-spacing:1px">赶快注册一个账号回来看看吧！</span>
                </template>

                <template #icon>
                    <MehOutlined />
                </template>
                <template #extra>
                    <router-link  :to="{path:'/register'}">
                        <a-button type="primary">注册</a-button>
                    </router-link>
                    <router-link  :to="{path:'/login'}">
                        <a-button >登录</a-button>
                    </router-link>
                </template>
            </a-result>
        </div>
        <myFooter/>
    </div>
</template>
<script>
 
import myHead from '../components/myHead'
import myFooter from '../components/myFooter'
import { MehOutlined} from '@ant-design/icons-vue';

import {   } from '@/request.js'

export default {
    
    components:{myHead,myFooter,MehOutlined},
    data() {
        return {

        };
    },
    methods: {

    },
    created(){
    },
    mounted() {

    }
};
</script>
<style scoped>

</style>